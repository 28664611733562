<style>
  @import './assets/styles/styles.css';
</style>

<template>
  <v-app>
    <v-main>
      <router-link to="/"><div class="header"></div></router-link>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
