<template>
  <div class="home">
    <div class="calendar-wrapper-outer">
      <div class="calendar-wrapper">
        <img class="instructions-image" src="~@/assets/images/instructions.png">
        <div class="date-picker-wrapper">
        <vc-date-picker
          v-model='dateSelected'
          mode="date"
          @dayclick="onDayClick"
          :available-dates='datesWithData'
          is-expanded
          />
      </div>
    </div>
    <div class="museum-link">
            <a href="https://kalamazoomuseum.org/">Back to kalamazoomuseum.org</a>
          </div>
    </div>
    <div class="tshirt-picker-wrapper-outer">
      <div v-if="shirtsForSelectedDay.length > 0" class="tshirt-picker-wrapper">
        <div  class = "tshirt-thumbnail animate__animated animate__fadeIn" v-for="shirt in shirtsForSelectedDay" :key="shirt.message">
          <router-link :to="{ path: '/Tshirt/' + shirt.tshirtID }"><img v-bind:src="shirt.tshirtImageData"></router-link>
        </div>
      </div>
      <div v-else class="tshirt-picker-wrapper animate__animated animate__fadeIn">No t-shirts have been created for this day </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'animate.css';

export default {
  name: 'Home',
    metaInfo() {
        return {
            title: "Kalamazoo Valley Museum - T-Shirt Repository",
        }
    },
  data: () => ({
    date: new Date(),
    /* timezone: "est", */
    datesWithData: new Array,
    shirtsForSelectedDay: new Array,
    url: "" //"http://localhost:81/kvm-database/"
  }),
  computed: {
    dateSelected: {
      get () {
         return this.$store.state.dateSelected
      },
      set (newValue) {
        return this.$store.dispatch('setDateSelected', newValue)
      }
    }
  },
  methods: {
    getDays: function() {
        axios.get(this.url + 'ajaxfile.php', {
            params: {
                getDays: true
            }
        }).then((response)  => {
            response.data.forEach(day => {
              if(day){
                this.datesWithData.push(new Date(day.year, day.month-1, day.theday))
              }
            });
              console.log(this.datesWithData)
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getShirtsByDay: function(day) {
        console.log('getting shirts for: ' + day)
        console.log(day)
        axios.get(this.url + 'ajaxfile.php', {
        params: {
            getShirtsByDay: true,
            day: day.year + "-" + day.month + "-" + day.day
        }
        }).then((response)  => {
            console.log(response.data)
            this.shirtsForSelectedDay = response.data
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onDayClick(day) {
      this.dateSelected = day
      this.getShirtsByDay(day)
      console.log(day)
    },
    routeLoaded() {
      console.log("load: ")
      console.log(this.dateSelected)
      this.getShirtsByDay( this.dateSelected)
    }
  },
  watch: {
      $route() {
        this.$nextTick(this.routeLoaded);
      }
  },
  created() {
    this.getDays()
      this.getShirtsByDay( this.dateSelected)
  },
  mounted() {
    this.$router.onReady(() => this.routeLoaded());
  },
    template: `<router-view/>`

}
</script>
