import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import Tshirt from '../views/Tshirt.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Kalamazoo Valley Museum - T-Shirt Repository',
    }
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test
  },
  {
    path: '/Tshirt/:TShirtID',
    name: 'Tshirt',
    component: Tshirt,
    meta: {
      title: 'Kalamazoo Valley Museum - T-Shirt Repository',
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
