<template>
  <div class="test">
    			<input type='button' @click='allRecords()' value='Select All users'>
			<br><br>

			<!-- Select record by ID -->
			<input type='text' v-model='tshirtID' placeholder="Enter tshirtID between 1 - 24">
			<input type='button' @click='getTshirtImage()' value='Select tshirt by ID'>
			<br><br>

			<!-- List records -->
<!-- 			<table border='1' width='80%' style='border-collapse: collapse;'>
				<tr>
					<th>ID</th>
					<th>Date</th>
				</tr>

                <tr v-for="(tshirt) in tshirts" :key="tshirt.tshirtID">
					<td>{{ tshirt.tshirtID }}</td>
					<td>{{ tshirt.tshirtCreatedDate }}</td>
				</tr>
			</table> -->
            <img v-bind:src="tshirtImage" id="tshirt-image" />
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Test',

    data: () => ({
        tshirts: "",
        days: "",
        tshirtID: 50,
        tshirtImage : "",
        url: "http://localhost:81/kvm-database/"
    }),
    methods: {
        allRecords: function(){

            axios.get(this.url + 'ajaxfile.php')
            .then((response)  => {
                this.tshirts = response.data;
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        recordByID: function(){
            if(this.tshirtID > 0){
                console.log("id: " + this.tshirtID)
                axios.get(this.url + 'ajaxfile.php', {
                    params: {
                        getTshirt: true,
                        tshirtID: this.tshirtID
                    }
                }).then((response)  => {
                    this.tshirts = response.data;
                    console.log(response)
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        getDays: function() {
            axios.get(this.url + 'ajaxfile.php', {
                params: {
                    getDays: true
                }
            }).then((response)  => {
                this.days = response.data;
                console.log(response)
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getTshirtImage: function() {
             axios.get(this.url + 'ajaxfile.php', {
                params: {
                    getImage: true,
                    tshirtID: this.tshirtID
                }
            }).then((response)  => {
                this.tshirtImage = response.data[0].tshirtImageData;
                console.log(response.data[0].tshirtImageData)
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>
