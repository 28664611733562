<template>
  <div class="tshirt-wrapper">
      <div class="tshirt-sidebar">
          <div class="sticker-image">
              <img src="~@/assets/images/KVM-bug.png">
          </div>
          <div class="tshirt-buttons">
              <router-link to="/"><button class="button-back" /></router-link>
              <button class="button-download" @click="downloadImage()"/>
          </div>
          <div class="social-buttons">
              <div class="elfsight-app-73fbbd28-3e57-4255-a7e6-d5f2d3a8e569"></div>
          </div>
          <div class="museum-link">
            <a href="https://kalamazoomuseum.org/">Back to kalamazoomuseum.org</a>
          </div>
      </div>
      <div class="tshirt-image-wrapper">
        <!-- <img v-bind:src="tshirtImage" id="tshirt-image" class="animate__animated animate__fadeIn" /> -->
        <canvas width="1000" height="904" id="tshirt-canvas" class="animate__animated animate__fadeIn" ></canvas>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import watermark from "watermarkjs";
import elfsight from '../platform.js'

export default {
  name: 'Tshirt',
  metaInfo() {
      return {
          title: "Kalamazoo Valley Museum - T-Shirt Repository",
      }
  },
  data: () => ({
    tshirtImage: "",
    url: ""// "http://localhost:81/kvm-database/"
  }),
  computed: {
    tshirtID(){ return parseInt(this.$route.params.TShirtID) },
  },
  methods: {
    getTshirtImage: function() {
        console.log("getTshirtImage")
        var bugData;
        var tshirtData;
        axios.get(this.url + 'ajaxfile.php', {
        params: {
            getImage: true,
            tshirtID: this.tshirtID
        }
        }).then((response)  => {

            tshirtData = response.data[0].tshirtImageData;
            this.tshirtImage = tshirtData
           // console.log(response.data[0].tshirtImageData)
           this.mergeImages()
        })
        .catch(function (error) {
            console.log(error);
        });
        bugData = this.getBugImage('bug.txt')

        var shirtFile =new File([""], "filename");
        shirtFile = this.urltoFile(tshirtData, 'a.png', 'image/png')
          .then(function(file){
        })




    },
    mergeImages: function() {

      var canvas = document.getElementById("tshirt-canvas");
      var ctx = canvas.getContext("2d");

      var img1 = this.loadImage('/KVM-bug_1000x904.png', main);
      var img2 = this.loadImage(this.tshirtImage, main);

      var imagesLoaded = 0;
      function main() {
          imagesLoaded += 1;

          if(imagesLoaded == 2) {
              // composite now
              ctx.drawImage(img1, 0, 0);

              ctx.globalAlpha = 1;
              ctx.drawImage(img2, 0, 0);
          }
      }
    },
    getBugImage: function(bugFile) {
        var imageData;
        fetch(bugFile)
        .then(response => response.text())
        .then(data => {
          // Do something with your data
          imageData = data
          //console.log(data);
        });
        return imageData;
    },
    downloadImage: function() {
        var a = document.createElement("a");
        a.href = document.getElementById('tshirt-canvas').toDataURL()
        a.download = "Kalamazoo Valley Museum T-Shirt " + this.tshirtID + ".png";
        a.click();
    },
    dataURLtoFile: function(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    },
     urltoFile: function(url, filename, mimeType){
      mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
      return (fetch(url)
          .then(function(res){return res.arrayBuffer();})
          .then(function(buf){return new File([buf], filename, {type:mimeType});})
      );
    },
    loadImage: function(src, onload) {
    var img = new Image();

    img.onload = onload;
    img.src = src;

    return img;
}

  },
  watch: {
       '$route' (to,from)  {
        console.log("watch route shirt")
        this.getTshirtImage();
      }
  },
  route: {
    activate() {
        this.$nextTick(function () {
           console.log("watch route shirt")
        this.getTshirtImage();
        })
    }
},
  mounted() {
      console.log(this.tshirtID)
      this.getTshirtImage();
  }

}
</script>