import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dateSelected: new Date()
  },
  mutations: {
    setDateSelected(state, dateSelected) {
      state.dateSelected = dateSelected;
    },
  },
  actions: {
    setDateSelected({ commit }, newValue) {
      commit("setDateSelected", newValue);
    },
  },
  modules: {
  }
})
